<template>
    <div class="container">
        <h1>No Frontend Yet</h1>
        <img src="./assets/pico.webp" class="pico-img" />
    </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    //HelloWorld
  }
}
</script>

<style>
    .pico-img {
        max-width: 50vw;
        width: 50%;
        height: auto;
    }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
